@import '../common/mixins';
@import '../../components/scee_card/component.scss';

html {
  font-size: 13px;
}

.full-height-icon {
  height: 100% !important;
}

.new-form-container {
  background-color: #F2F5F8;

  .new-form {
    max-width: 960px;
    background-color: #fff;
  }

  .go-back {
    position: absolute;
    left: 40%;
    top: 53px;

    @include md {
      left: 60px;
    }
  }
}


.loading-spinner {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.65384615em 0em 0em -0.65384615em;
  width: 1.30769231em;
  height: 1.30769231em;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
}

.tw-bg-white > .loading-spinner {
  border-color: #00D4B4 transparent transparent;
}

.custom-radio-input {
  display: block;
  position: absolute;
  z-index: 1;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  top: 12px;
  right: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #FFFFFF;
    border: 2px solid #D3DCE6;
    border-radius: 50%;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #00D4B4;
    border: 2px solid #00D4B4;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 1px;
    right: 1px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    border: 3px solid #FFFFFF;
  }

  &.left {
    right: auto;
    left: 0
  }

  &.small {
    top: 16px;

    .checkmark {
      width: 16px;
      height: 16px;
    }

    .checkmark:after {
      top: 0px;
      right: 0px;
      width: 12px;
      height: 12px;
    }
  }
}


.custom-checkbox-input {
  display: block;
  position: absolute;
  z-index: 1;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  top: 12px;
  right: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #FFFFFF;
    border: 2px solid #D3DCE6;
    border-radius: 5px;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #00D4B4;
    border: 2px solid #00D4B4;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.left {
    right: auto;
    left: 0
  }

  &.small {
    top: 16px;

    .checkmark {
      width: 16px;
      height: 16px;
    }

    .checkmark:after {
      left: 4px;
      top: 1px;
      width: 5px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
    }
  }
}

.project-list-component {
  .project-list-item {
    width: 304px;
    height: auto;

    &-img {
      width: 80px;
      height: 48px;
    }
  }

  .custom-radio-input,
  .custom-checkbox-input {
    position: relative;
    top: 0;
    margin: 0;

    .checkmark {
      top: calc(24px - 12.5px);
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.clean-number-container {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2ad1b3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2ad1b3 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mobile-hover-dropdown {
  .menu {
    padding-left: 10px;
    &.hidden {
      display: none;
    }
  }
}

.centered-icon {
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
}

a {
  color: #00D4B4;

  &:hover {
    color: #00D4B4;
  }
}

h1, h2, h3, h4, h5 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.2857em;
  margin: calc(2rem - 0.14285em) 0em 1rem;
  font-weight: 400;
  padding: 0em;
}

h2 {
  font-size: 1.714rem;
}

.ui.secondary.menu .dropdown.item:hover, .ui.secondary.menu .link.item:hover, .ui.secondary.menu a.item:hover {
  background: none;
  color: none;
}

.ui.progress {
  .bar {
    background-color: #00D4B4 !important;
  }
}

.ui.menu .item::before {
  content: none;
}

.ui.link.menu .item:hover, 
.ui.menu .dropdown.item:hover, 
.ui.menu .link.item:hover, 
.ui.menu a.item:hover {
  background: none;
}

body {
  color: #333338;
}