@mixin md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin xxl {
  @media screen and (min-width: 1366px) {
    @content;
  }
}