.scee-card-container {
  min-width: 268px;
  max-width: 268px;
  align-self: flex-start;

  .scee-img-container {
    height: 160px;

    &:after {
      content: "";
      width: 100%;
      height: 32px;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(180deg, #000000ad 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 0.5rem 0.5rem 0px 0px;
    }

    img {
      height: 160px;
    }

    .state-ribbon {
      position: absolute;
      width: 156px;
      right: -15px;
      bottom: 17px;
      -webkit-transform: rotate(-30deg);
      transform: rotate(-30deg);
      border: 1px solid #F5F8FE;
      color: white;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      line-height: 140%;
      text-transform: uppercase;

      &[data-state="out_of_stock"] {
        right: -29px;
        bottom: 34px;
        transform: rotate(316deg);
      }
    }
  }
}
